import React from 'react';
import { Link } from 'gatsby';
import PropTypes from 'prop-types';
import styles from './styles.module.scss';

function NewsPagination({ currentPage, numPages }) {
  const isFirst = currentPage === 1;
  const isLast = currentPage === numPages;
  const newPostPage =
    currentPage - 1 === 1
      ? 'blog'
      : `blog/page/${(currentPage - 1).toString()}`;
  const oldPostPage = `blog/page/${(currentPage + 1).toString()}`;

  return (
    <div className={styles.newsPagination}>
      {!isLast && (
        <Link to={oldPostPage} rel="next">
          « Older posts
        </Link>
      )}
      {!isFirst && (
        <Link to={newPostPage} rel="prev" className={styles.paginationRight}>
          Newer posts »
        </Link>
      )}
    </div>
  );
}

NewsPagination.propTypes = {
  currentPage: PropTypes.number.isRequired,
  numPages: PropTypes.number.isRequired,
};

export default NewsPagination;
