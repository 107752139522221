import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import styles from './styles.module.scss';

function NewsAvatar({ author }) {
  const avatarClass = author.replace(' ', '');

  return (
    <div
      className={classnames(styles.newsAvatar, styles[`author${avatarClass}`])}
    />
  );
}

NewsAvatar.propTypes = {
  author: PropTypes.string.isRequired,
};

export default NewsAvatar;
