import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import styles from './styles.module.scss';

function Heading({ children, level, withSpacing }) {
  const headings = {
    1: 'h1',
    2: 'h2',
    3: 'h3',
    4: 'h4',
    5: 'h5',
    6: 'h6',
    sub: 'div',
  };
  const HeadingTag = headings[level];

  return (
    <HeadingTag
      className={classnames(styles.heading, styles[`heading${level}`], {
        [styles.withSpacing]: withSpacing,
      })}
    >
      {children}
    </HeadingTag>
  );
}

Heading.defaultProps = {
  level: 1,
  withSpacing: false,
};

Heading.propTypes = {
  children: PropTypes.node.isRequired,
  level: PropTypes.oneOf([1, 2, 3, 4, 5, 6, 'sub']),
  withSpacing: PropTypes.bool,
};

export default Heading;
