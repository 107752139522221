import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'gatsby';
import classnames from 'classnames';
import styles from './styles.module.scss';

class NewsNav extends Component {
  state = {
    showNav: false,
  };

  toggleNav = () => {
    this.setState({
      showNav: !this.state.showNav,
    });
  };

  render() {
    const { showActive } = this.props;

    return (
      <div className={styles.newsNav}>
        <Link className={styles.logo} to="/">
          Scribbledwall - Home-made geekery
        </Link>

        <button
          className={styles.menuToggle}
          type="button"
          onClick={this.toggleNav}
        >
          Menu
        </button>

        <ul
          className={classnames(styles.navList, {
            [styles.navVisible]: this.state.showNav,
          })}
        >
          <li className={styles.navListItem}>
            <Link className={styles.navLink} to="/">
              Home
            </Link>
          </li>
          <li className={styles.navListItem}>
            <Link
              className={classnames(styles.navLink, {
                [styles.navLinkActive]: showActive,
              })}
              to="/blog"
            >
              News{showActive && '*'}
            </Link>
          </li>
          <li className={styles.navListItem}>
            <a
              className={styles.navLink}
              href="http://www.redbubble.com/people/scribbledwall/shop"
              target="_blank"
              rel="noopener noreferrer"
            >
              Shop
            </a>
          </li>
          <li className={styles.navListItem}>
            <Link className={styles.navLink} to="/about">
              About
            </Link>
          </li>
          <li className={styles.navListItem}>
            <Link className={styles.navLink} to="/contact">
              Contact
            </Link>
          </li>
        </ul>
      </div>
    );
  }
}

NewsNav.defaultProps = {
  showActive: false,
};

NewsNav.propTypes = {
  showActive: PropTypes.bool,
};

export default NewsNav;
