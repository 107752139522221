import React from 'react';
import { graphql } from 'gatsby';

import Page from '../components/Page';
import SEO from '../components/seo';
import NewsNav from '../components/News/NewsNav';
import NewsContent from '../components/News/NewsContent';
import NewsFooter from '../components/News/NewsFooter';
import NewsPostLayout from '../components/News/NewsPostLayout';
import NewsPostStyled from '../components/News/NewsPostStyled';
import NewsExcerptReadMoreLink from '../components/News/NewsExcerptReadMoreLink';
import NewsPagination from '../components/News/NewsPagination';

function BlogListTemplate({ data, pageContext }) {
  const posts = data.allMarkdownRemark.edges;
  const { currentPage, numPages } = pageContext;

  return (
    <Page>
      <SEO title="News" keywords={[`blog`, `gatsby`, `javascript`, `react`]} />

      <NewsNav showActive />

      <NewsContent>
        {posts.map(({ node }, index) => {
          const isLeadPost = index === 0;
          const textOnlyExcerpt = node.excerpt.replace(/(<([^>]+)>)/gi, '');
          const showExcerptLink =
            textOnlyExcerpt.length >= 155 &&
            textOnlyExcerpt.lastIndexOf('…') > 155;

          return (
            <div key={node.fields.slug} style={{ marginBottom: '80px' }}>
              <NewsPostLayout
                isLeadPost={isLeadPost}
                slug={`/blog${node.fields.slug}`}
                author={node.frontmatter.author}
                date={node.frontmatter.date}
                title={node.frontmatter.title}
                commentsCount={
                  node.frontmatter.comments &&
                  JSON.parse(node.frontmatter.comments).length
                }
              >
                <NewsPostStyled>
                  <div dangerouslySetInnerHTML={{ __html: node.excerpt }} />
                </NewsPostStyled>

                {showExcerptLink && (
                  <NewsExcerptReadMoreLink slug={node.fields.slug} />
                )}
              </NewsPostLayout>
            </div>
          );
        })}

        <NewsPagination currentPage={currentPage} numPages={numPages} />
      </NewsContent>

      <NewsFooter />
    </Page>
  );
}

export default BlogListTemplate;

export const pageQuery = graphql`
  query BlogList($skip: Int!, $limit: Int!) {
    allMarkdownRemark(
      sort: { fields: [frontmatter___date], order: DESC }
      limit: $limit
      skip: $skip
    ) {
      edges {
        node {
          excerpt(pruneLength: 160, format: HTML)
          fields {
            slug
          }
          frontmatter {
            date(formatString: "dddd, Do MMMM YYYY")
            title
            author
            comments
          }
        }
      }
    }
  }
`;
