import React from 'react';
import PropTypes from 'prop-types';
import styles from './styles.module.scss';

function NewsCommentsCount({ commentsCount, slug }) {
  let commentsText = 'No Comments';

  if (commentsCount > 1) {
    commentsText = `${commentsCount} Comments`;
  }

  if (commentsCount === 1) {
    commentsText = `${commentsCount} Comment`;
  }

  return (
    <div className={styles.newsCommentsCount} id="comments">
      {(!slug || !commentsCount) && commentsText}
      {slug && commentsCount > 0 && (
        <a href={`${slug}#comments`}>{commentsText}</a>
      )}
    </div>
  );
}

NewsCommentsCount.propTypes = {
  commentsCount: PropTypes.number,
  slug: PropTypes.string,
};

export default NewsCommentsCount;
