import React from 'react';
import { Link } from 'gatsby';
import PropTypes from 'prop-types';
import styles from './styles.module.scss';

function NewsAvatar({ slug }) {
  return (
    <Link to={slug} className={styles.readMore}>
      Read the rest »
    </Link>
  );
}

NewsAvatar.propTypes = {
  slug: PropTypes.string.isRequired,
};

export default NewsAvatar;
