import React from 'react';
import styles from './styles.module.scss';

function NewsFooter() {
  return (
    <div className={styles.newsFooter}>
      <p>
        Copyright 2009-{new Date().getFullYear()}.{' '}
        <a href="#manna-quinn">Manna Quinn</a> and{' '}
        <a href="#frankie-biscuit">Frankie Biscuit</a>. All rights reserved.
      </p>
    </div>
  );
}

export default NewsFooter;
