import React from 'react';
import PropTypes from 'prop-types';
import styles from './styles.module.scss';

function NewsContent({ children }) {
  return (
    <div className={styles.newsContent}>
      <div className={styles.hero}>
        <div className={styles.heroImage} />
      </div>

      <div className={styles.contentContainer}>
        <div className={styles.content}>{children}</div>
      </div>
    </div>
  );
}

NewsContent.propTypes = {
  children: PropTypes.node.isRequired,
};

export default NewsContent;
