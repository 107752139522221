import React from 'react';
import { Link } from 'gatsby';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import styles from './styles.module.scss';

import NewsAvatar from '../NewsAvatar';
import NewsCommentsCount from '../NewsCommentsCount';
import Heading from '../../Heading';

function NewsPostLayout({
  slug,
  isLeadPost,
  author,
  date,
  title,
  commentsCount,
  children,
}) {
  return (
    <div className={styles.newsPostLayout}>
      <div className={styles.heading}>
        <div className={styles.avatar}>
          <NewsAvatar author={author} />
        </div>

        <div>
          {!isLeadPost && (
            <div className={styles.meta}>
              {date} by <strong>{author}</strong>
            </div>
          )}

          <Heading level={isLeadPost ? 2 : 3}>
            {slug ? <Link to={slug}>{title || slug}</Link> : title}
          </Heading>

          {isLeadPost && (
            <div className={classnames(styles.meta, styles.metaLead)}>
              {date} by <em>{author}</em>
            </div>
          )}
        </div>
      </div>

      <div className={styles.content}>{children}</div>

      <NewsCommentsCount commentsCount={commentsCount} slug={slug} />
    </div>
  );
}

NewsPostLayout.defaultProps = {
  isLeadPost: false,
  commentsCount: 0,
};

NewsPostLayout.propTypes = {
  slug: PropTypes.string,
  isLeadPost: PropTypes.bool,
  author: PropTypes.string.isRequired,
  date: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  commentsCount: PropTypes.number,
  children: PropTypes.node.isRequired,
};

export default NewsPostLayout;
